<template>
	<div>
		<CustomTableRiskReport
			:total-pages="totalPages"
			:total-items="totalItems"
			:clinics="clinics"
			:is-mobile="isMobile"
			:mobile-role="mobileRole"
			@export="exportExcel"
			@exportPlatformIndex="exportPlatformIndex"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headers"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
						>{{ header.title }}</th
					>
				</tr>
			</template>
		</CustomTableRiskReport>
	</div>
</template>

<script>
import { GET_REPORT_CUSTOM_FORM_EXPORT, GET_REPORT_PLATFORM_INDEX } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("report")
import { MONTH } from "@/shared/plugins/constants"

export default {
	name: "ReportRiskAssessmentManagement",

	props: {
		// Support embedded in mobile
		isMobile: {
			type: [Boolean, String],
			default: false,
		},
		token: {
			type: [String],
			default: null,
		},
		mobileRole: {
			type: [String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("ReportScheduleForm.Clinics"),
					class: "fit report-schedule__clinic",
				},
				{
					title: this.$t("ReportScheduleForm.Jan"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Feb"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Mar"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Apr"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.May"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jun"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Jul"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Aug"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sep"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Oct"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Nov"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Dec"),
					class: "text-nowrap",
				},
				{
					title: this.$t("ReportScheduleForm.Sum"),
					class: "fit text-center",
				},
			],

			schedules: [],
			MONTH: MONTH,
		}
	},

	computed: {
		...mapState(["schedule", "clinics", "totalItems", "totalPages"]),
	},
	methods: {
		...mapActions({
			GET_REPORT_CUSTOM_FORM_EXPORT,
			GET_REPORT_PLATFORM_INDEX,
		}),
		exportExcel: async function (params = {}) {
			const data = { ...params }
			if (this.isMobile) data.token = this.token
			this.GET_REPORT_CUSTOM_FORM_EXPORT(data)
		},
		exportPlatformIndex: async function (params = {}) {
			const data = { ...params }
			if (this.isMobile) data.token = this.token
			this.GET_REPORT_PLATFORM_INDEX(data)
		},
	},
}
</script>

<style lang="scss" scoped>
.report-schedule {
	&__clinic {
		min-width: 120px;
		max-width: 200px;
		white-space: nowrap !important;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/deep/ .multiselect__option {
	white-space: normal;
}
</style>
